import React from 'react'
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Layout from "../components/layout";
import Seo from "../components/seo"
import { PageLinks } from '../common/site/page-static-links';
import PropertyImg1 from "../images/properties_img_1.png";
import PropertyImg2 from "../images/properties_img_2.png";
import PropertyImg3 from "../images/properties_img_3.png";

const Banner = loadable(() => import("../components/Banner/Banner"));
const PropertySlider = loadable(() => import("../components/PropertySlider/AreaGuideSlider"));
const AreaGuideDetailDesc = loadable(() => import("../components/AreaGuideDetailDesc/AreaGuideDetailDesc"));
const GoogleSingleMap = loadable(() => import("../components/maps/google/single"));
const LeafletSingleMap = loadable(() => import("../components/maps/leaflet/single"));
const FloatingButtons = loadable(() => import("../components/FloatingButtons/FloatingButtons"));

const AreaguideDetails = ({ data }, props) => {
    const areaguideData = data?.strapiAreaGuide

    const mapService = process.env.GATSBY_MAP_PROVIDER == "leaflet" ? "leaflet" : "google";

    let floatingNumber = '';

    if (areaguideData?.select_office?.phone) {
        floatingNumber = areaguideData?.select_office?.phone
    } else if (areaguideData?.select_office?.sales_phone) {
        floatingNumber = areaguideData?.select_office?.sales_phone
    } else if (areaguideData?.select_office?.lettings_phone) {
        floatingNumber = areaguideData?.select_office?.lettings_phone
    }

    const bannerCTAS = {
        cta_1_title: "Property for Sale",
        cta_1_link: {
            link_type: "internal",
            slug: PageLinks.results_sales,
            title: "Property for Sale",
        },
        cta_2_title: "Property to Rent",
        cta_2_link: {
            link_type: "internal",
            slug: PageLinks.results_lettings,
            title: "Property to Rent",
        }
    }

    return (
        <Layout footerContact={"footercontact"} >
            <Banner
                title={areaguideData?.title}
                tag="landing-banner"
                bannerClass="inner-banner"
                id={areaguideData.strapi_id}
                imagetransforms={areaguideData.imagetransforms}
                image={areaguideData.banner_image}
                {...areaguideData}
                {...bannerCTAS}
            />

            <AreaGuideDetailDesc areaguideData={areaguideData} officeData={areaguideData?.select_office} />

            <div className='areaguide-map-wrapper'>
                {mapService === "google" &&
                    <GoogleSingleMap lat={areaguideData.latitude} lng={areaguideData.longitude} />
                }
                {mapService === "leaflet" &&
                    <LeafletSingleMap lat={areaguideData.latitude} lng={areaguideData.longitude} />
                }
            </div>

            <PropertySlider areaname={areaguideData.title}/>

            <FloatingButtons number={floatingNumber} />
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiAreaGuide
    return (
        <Seo title={PageData.title} description={PageData.title} />
    )
}

export default AreaguideDetails

export const query = graphql`
query ($page_id: Int) {
    strapiAreaGuide(strapi_id: {eq: $page_id}) {
        ...AreaGuideFragment
        banner_content {
            data {
                banner_content
            }
        }
        banner_image {
          alternativeText
          url
        }
        latitude
        longitude
        add_content {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                ...PlainContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
                ...ImageFragment
            }
        }
        select_office {
            ...OfficeFragment
        }
    }
    
    allStrapiOffice(filter: {publish: {eq: true}}, limit: 1) {
        edges {
            node {
                ...OfficeFragment
            }
        }
    }
  }
`